<template>
  <div>
    <div class="wrapper">
      <section class="section section-dark jpadding jpadding-25 section-unfixed">
        <div class="appointment-grid">
          <div class="jtitle text-center mb-5">
            <h3><i class="fad fa-exclamation text-gold fa-3x"></i></h3>
            <h3>Payment failed!</h3>
          </div>
          <div>
            <p>
              The payment did not go through. Please check if you have enough funds, then try again.
            </p>
            <p class="small">
              If the problem persists despite a functional card and sufficient funds, please contact our team.
            </p>
          </div>
        </div>

        <div class="mt-5 text-center">
          <router-link :to="{ name: getDashboardRouteName() }" class="jbtn-watch-link text-gold">
            <i class="fal fa-arrow-left"></i> BACK TO DUKE CLUB
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import firebase from "@/firebase/firebaseInit";
const auth = firebase.auth;

export default {
  name: "PaymentFailed",
  data() {
    return {
    };
  },
  methods: {
    getDashboardRouteName() {
      if (!auth.currentUser)
        return "Login";

      const type = this.$store.getters.getUserTypeString;
      if (type) {
        this.dashboardRouteName = "Dashboard" + type;
      } else {
        return "Login";
      }

      return this.dashboardRouteName;
    },

  }
};
</script>

<style scoped></style>
